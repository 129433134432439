<template>
    <div class="checkbox-wrapper">
        <input type="checkbox" class="sc-gJwTLC ikxBAC" :checked="checked" @change="$emit('update')">
    </div>
</template>

<script>
export default {
    props: {
        checked: {
          type: Boolean,
          default: false,
      },
    }
}
</script>

<style scoped>
.checkbox-wrapper{
    display: flex;
    justify-content: center;
}
.checkbox-wrapper .ikxBAC {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    position: relative;
    width: 30px;
}

.checkbox-wrapper .ikxBAC::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
}

.checkbox-wrapper .ikxBAC,
.checkbox-wrapper .ikxBAC::after {
    transition: all 100ms ease-out;
}

.checkbox-wrapper .ikxBAC::after {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 14px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 14px;
}

.checkbox-wrapper input[type=checkbox] {
    cursor: default;
}

.checkbox-wrapper .ikxBAC:hover {
    background-color: #c9cbcd;
    transition-duration: 0s;
}

.checkbox-wrapper .ikxBAC:checked {
    background-color: #32CD32;
}

.checkbox-wrapper .ikxBAC:checked::after {
    background-color: #fff;
    left: 13px;
}

.checkbox-wrapper :focus:not(.focus-visible) {
    outline: 0;
}

.checkbox-wrapper .ikxBAC:checked:hover {
    background-color: #66C266;
}
</style>
  