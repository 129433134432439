<template>
    <v-dialog v-model="isOpen" max-width="800px">
      <v-card>
        <v-card-text class="pt-5 pb-0">
            <!-- Form -->
            <v-form ref="form" v-model="valid" lazy-validation  @submit.prevent="submitForm">
                <v-row>
                    <v-col xs="12" sm="9">
                        <v-text-field
                            v-model="name"
                            :counter="30"
                            :rules="nameRules"
                            label="Nombre de la promoción"
                            required                        
                        ></v-text-field>
                    </v-col>

                    <v-col xs="12" sm="3">
                        <v-select
                            v-model="state"
                            :items="stateSelect"
                            item-text="text"
                            item-value="value"
                            label="Estatus"
                            :rules="[v => !!v || 'Seleccione una opción']"
                            required                            
                        ></v-select>
                    </v-col>

                    <v-col xs="12" sm="3">
                        <v-select
                            v-model="channel"
                            :items="channelSelect"
                            :rules="[v => !!v || 'Seleccione una opción']"
                            label="Canal"
                            required
                        ></v-select>
                    </v-col>

                    <v-col xs="12" sm="9">
                        <v-file-input
                            v-model="file"
                            label="Seleccionar archivo"
                            truncate-length="15"
                            accept="application/pdf"
                            required
                            :rules="fileRules"
                        ></v-file-input>
                    </v-col>
                </v-row>

                <div v-show="hideButton">
                    <v-btn type="submit" class="form__button-send mt-4" :disabled="!valid">Enviar</v-btn>
                </div>
            </v-form>     
            <!-- End Form -->       
        </v-card-text> 
        
        <!-- Button Close -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="close">
            Cerrar
          </v-btn>
        </v-card-actions>
        <!-- End Button Close -->
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import axios from "@/plugins/axios";
  export default {
    props: {
      value: {
          type: Boolean,
          default: false,
      },
    },    
    data() {
        return {
            file: null,
            name: '',
            channel: '',
            state: 1,
            nameRules: [
                v => !!v || 'El nombre es requerido',
                v => (v && v.length <= 30) || 'El nombre debe tener menos de 30 carácteres',
            ],
            fileRules: [
                v => !!v || 'Debe seleccionar un archivo',
                v => !v || v.type === 'application/pdf' || 'Solo se permiten archivos PDF',
                v => !v || v.size <= 5 * 1024 * 1024 || 'El archivo debe ser menor a 5 MB'
            ],
            channelSelect: ['DT', 'PR'],
            stateSelect: [{ text: 'Activo', value: 1} , { text: 'Inactivo', value: 0 }],
            valid: true,
            hideButton: true
        };
    },
    methods: {
        async submitForm() {
            const isValid = this.$refs.form.validate(); 
            if (!isValid) return;

            this.hideButton = false;  
            const formData = new FormData();
            formData.append('name', this.name);
            formData.append('channel', this.channel);
            formData.append('state', this.state);
            formData.append('file', this.file);

            try {
                await axios.post('promotions', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                this.resetForm();
                this.$toast.success("Se registro la promoción correctamente.");
                this.$emit('success');
                this.isOpen = false;
            } catch (error) {
                if (error.response && error.response.status === 422) {                    
                    this.$toast.error("El archivo no puede exceder los 5 MB");                    
                }else{                    
                    this.$toast.error("Ocurrió un error, por favor vuelva a intentarlo."); 
                }               
            }

            this.hideButton = true;
        },
        close(){
            this.resetForm();
            this.isOpen = false; 
        },  
        resetForm(){
            this.name = '';
            this.state = 1;
            this.channel = null;
            this.file = null;

            this.$refs.form.reset();
        }        
    },
    computed:{
        isOpen: {
            get(){        
                return this.value;
            },
            set(newValue){
                this.$emit('input', newValue);
            }			
        },
    },
  };
  </script>
  
  <style scoped>
  .form__button-send{  
      background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
      color: #FFF !important;      
      font-size: 14px; 
      height: 30px !important;
      min-width: inherit !important;
      width: 100%;
      max-width: 200px;
  }
  </style>